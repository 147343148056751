import * as types from "../../actions/PNA/actionTypes";

export default function activityReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_ACTIVITIES_SUCCESS:
      return { ...state, allActivities: action.activities };

    default:
      return state;
  }
}
