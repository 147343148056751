import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import orders from "./PNA/orderReducer";
import orderAssignment from "./PNA/orderAssignmentReducer";
import attachmentReducer from "./PNA/attachmentReducer";
import activityReducer from "./PNA/activityReducer";
import clientCodeReducer from "./PNA/clientCodeReducer";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  orders: orders,
  orderAssignment: orderAssignment,
  attachments: attachmentReducer,
  activities: activityReducer,
  clientcodes: clientCodeReducer,
});

export default rootReducer;
