import Amplify, { Auth } from "aws-amplify";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import AWS from "aws-sdk";
import authorization from "./authorization";

class Authentication {
  constructor() {
    this.loggedIn = false;
    this.userName = "";
    this.role = "";
    this.users = [];
  }

  async signIn(username, password) {
    try {
      // UNCOMMENT - LEFT for TESTING
      // this.loggedIn = true;
      // this.userName = "Nisar";
      // this.userID = "stayingcool@gmail.com";
      // const payload = { action: 'TESTING' };
      // this.setRole(payload)
      // return new Promise((resolve, reject) => {
      //     resolve({ message: "Success" });
      // });

      this.clearLocalStorage();

      const user = await Auth.signIn(username, password);
      this.loggedIn = true;
      this.userName = user.signInUserSession.idToken.payload["given_name"];
      this.userID = user.signInUserSession.idToken.payload["email"];

      const payload = user.signInUserSession.getIdToken().decodePayload();

      if (!this.setRole(payload))
        return {
          code: "RoleNotAssignedException",
          message:
            "User is not yet ready for login. Please check with your admin...",
          name: "RoleNotAssignedException"
        };

      if (authorization.canFetchUsersFromCognito()) {
        this.getUsersFromCognito();
      }

      // TBD
      // if (authorization.canFetchUsersFromCognito()) {
      //   this.getUsersFromCognito();
      // }

      // this.users.splice(0, 0, [
      //   { Name: "sub", Value: "None" },
      //   { Name: "email_verified", Value: "true" },
      //   { Name: "given_name", Value: "None" },
      //   { Name: "email", Value: "None" },
      // ]);

      // this.users.splice(0, 0, [
      //   { Name: "sub", Value: "None" },
      //   { Name: "email_verified", Value: "true" },
      //   { Name: "given_name", Value: "Nisar S" },
      //   { Name: "email", Value: "stayingcool@gmail.com" },
      // ]);

      // this.users.splice(0, 0, [
      //   { Name: "sub", Value: "None" },
      //   { Name: "email_verified", Value: "true" },
      //   { Name: "given_name", Value: "Nisar E" },
      //   { Name: "email", Value: "emailfromanywhere@gmail.com" },
      // ]);
      return user;
    } catch (error) {
      this.loggedIn = false;
      console.log("error signing in", error);
      return error;
    }
  }

  async signUp(username, password, email, given_name) {
    try {
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          given_name
          // other custom attributes
        }
      });
      return user;
    } catch (error) {
      console.log("error signing up:", error);
      return error;
    }
  }

  async signOut() {
    try {
      this.clearLocalStorage();
      await Auth.signOut({ global: true });
      this.loggedIn = false;
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async confirmSignUp(username, code) {
    try {
      await Auth.confirmSignUp(username, code);
      return { name: "SUCCESS", message: "SUCCESS" };
    } catch (error) {
      console.log("error confirming sign up", error);
      return error;
    }
  }

  async resendConfirmationCode(email) {
    try {
      await Auth.resendSignUp(email);
      return { name: "SUCCESS", message: "SUCCESS" };
    } catch (error) {
      return error;
    }
  }

  async getConfirmationCode(email) {
    try {
      await Auth.forgotPassword(email);
      return { name: "SUCCESS", message: "SUCCESS" };
    } catch (error) {
      return error;
    }
  }

  async resetPassword(email, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      return { name: "SUCCESS", message: "SUCCESS" };
    } catch (error) {
      console.log("error confirming sign up", error);
      return error;
    }
  }

  isAuthenticated() {
    return this.loggedIn;
  }

  setRole(payload) {
    // UNCOMMENT - LEFT for TESTING.
    if (payload.action === "TESTING") {
      this.role = "admin";
      return true;
    }
    if (payload && payload["cognito:groups"]) {
      if (payload["cognito:groups"].includes("teamlead")) {
        this.role = "teamlead";
        return this.checkAdditionalRole(payload);
      } else if (payload["cognito:groups"].includes("admin")) {
        this.role = "admin";
        return this.checkAdditionalRole(payload);
      } else if (payload["cognito:groups"].includes("processor"))
        this.role = "processor";
      else if (payload["cognito:groups"].includes("orderdesk"))
        this.role = "orderdesk";
      else if (payload["cognito:groups"].includes("qcauditor"))
        this.role = "qcauditor";
      else if (payload["cognito:groups"].includes("zcadmin")) return false; // not assigned to any of the above groups!

      return true;
    } else return false;
  }

  checkAdditionalRole(payload) {
    if (authorization.canAssignOrders()) {
      return payload["cognito:groups"].includes("zcadmin");
    }
  }

  getUsersFromCognito() {
    const cognitoConfig = Amplify.Auth.configure();
    const cognitoIdPoolRegion = cognitoConfig.region;
    const cognitoUserPoolID = cognitoConfig.userPoolId;

    // Insert the first dummy user with title as "None" used for unassigning the order
    let usersFromCognito = [];

    let _this = this;

    Auth.currentCredentials().then(user => {
      const AWSconfig = new AWS.Config({
        apiVersion: "2016-04-18",
        credentials: user,
        region: cognitoIdPoolRegion
      });

      let params = {
        UserPoolId: cognitoUserPoolID
      };
      const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider(
        AWSconfig
      );
      cognitoidentityserviceprovider.listUsers(
        params,
        listUsersCallbackHandler
      );

      function listUsersCallbackHandler(err, userData) {
        if (err) console.log("Error in getCognitoUsers: ", err);
        else {
          usersFromCognito = usersFromCognito.concat(userData.Users);
          if (userData.PaginationToken) {
            params = {
              ...params,
              PaginationToken: userData.PaginationToken
            };
            // Call again as the user count is > 60 (AWS Cognito Limit)
            cognitoidentityserviceprovider.listUsers(
              params,
              listUsersCallbackHandler
            );
          }
          _this.setUsers(usersFromCognito);
        }
      }
    });
  }

  getUsers() {
    return this.users;
  }

  setUsers = usersFromCognito => {
    this.users = [];
    // Add cognito username in the same array as name/value pairs
    usersFromCognito.forEach(user => {
      this.users.push([
        ...user.Attributes,
        {
          Name: "Username",
          Value: user.Username
        }
      ]);
    });

    this.users.sort((a, b) => a[2].Value.localeCompare(b[2].Value));
    // Insert a dummy user at the top; used for unassigning user from orders
    this.users.splice(0, 0, [
      { Name: "sub", Value: "None" },
      { Name: "email_verified", Value: "true" },
      { Name: "given_name", Value: "None" },
      { Name: "email", Value: "None" }
    ]);
  };

  clearLocalStorage() {
    sessionStorage.removeItem("showPendingReview");
    sessionStorage.removeItem("showUnassigned");
    sessionStorage.removeItem("showReviewClarification");
    sessionStorage.removeItem("showReviewCompleted");
    sessionStorage.removeItem("tabID");
    sessionStorage.removeItem("navigatingFrom");
    localStorage.clear();
  }
}

const authClient = new Authentication();
export default authClient;
