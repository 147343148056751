import * as types from "../../actions/PNA/actionTypes";
import toast from "../../../common/utils/toast";

export default function clientCodeReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_CLIENTCODE_SUCCESS:
      return {
        ...state,
        allClientCode: action.clientcode.map((item) => {
          return {
            ...item,
            name: unescape(item.name),
          };
        }),
      };

    case types.CREATE_CLIENTCODE_SUCCESS:
      toast.default("ClientCode Created: " + unescape(action.clientcode.name));
      return {
        ...state,
        allClientCode: [
          // Insert in the begining
          ...state.allClientCode.slice(0, action.clientcode),
          // Our new client code
          { ...action.clientcode, name: unescape(action.clientcode.name) },
          ...state.allClientCode.slice(action.clientcode),
        ],
      };

    case types.SEARCH_CLIENTCODE_SUCCESS:
      return {
        ...state,
        searchedClientCode: action.clientcodeName,
      };

    default:
      return state;
  }
}
