import * as types from "../../actions/PNA/actionTypes";
import toast from "../../../common/utils/toast";
import moment from "moment";

export default function orderReducer(state = [], action) {
  switch (action.type) {
    case types.CREATE_ORDER_SUCCESS:
      toast.default("Order Created: " + action.order.id);
      const newOrder = {
        ...action.order,
        orderDate: moment(action.order.orderDate).format("MM-DD-YYYY"),
        productID: unescape(action.order.productID),
        address: unescape(action.order.address),
        county: unescape(action.order.county),
        clientCode: unescape(action.order.clientCode),
      };

      return { ...state, allOrders: [...state.allOrders, { ...newOrder }] };

    case types.UPDATE_SPINNER_STATUS:
      return { ...state, isLoading: { status: action.status } };

    case types.UPDATE_ORDER_SUCCESS:
      toast.default("Order Updated: " + action.order.id);
      // TBD after subscriptions utilise this reducer to update state
      // return state.map(order => order.id === action.order.id ? action.order : order);

      const updatedOrder = {
        ...action.order,
        productID: unescape(action.order.productID),
        address: unescape(action.order.address),
        county: unescape(action.order.county),
        clientCode: unescape(action.order.clientCode),
      };

      return {
        ...state,
        allOrders: state.allOrders.map((order) =>
          order.id === action.order.id ? updatedOrder : order
        ),
      };

    case types.LOAD_ORDERS_SUCCESS:
      return {
        allOrders: action.orders.map((order) => {
          // TBD - Do this on DB/Serverside
          return {
            ...order,
            orderDate: moment(order.orderDate).format("MM-DD-YYYY"),
            productID: unescape(order.productID),
            address: unescape(order.address),
            county: unescape(order.county),
            clientCode: unescape(order.clientCode),
          };
        }),
      };

    case types.LOAD_REPORT_ORDERS_SUCCESS:
      return {
        reportOrders: action.orders.map((order) => {
          return {
            ...order,
            productID: unescape(order.productID),
            address: unescape(order.address),
            county: unescape(order.county),
            clientCode: unescape(order.clientCode),
            borrowerName: unescape(order.borrowerName),
            comments: unescape(order.comments),
          };
        }),
      };

    case types.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        orderToEdit: action.order,
      };

    case types.LOAD_ORDERS_BY_TYPE_SUCCESS:
      return {
        allOrders: action.orders.map((order) => {
          // TBD - Do this on DB/Serverside
          return {
            ...order,
            orderDate: moment(order.orderDate).format("MM-DD-YYYY"),
            productID: unescape(order.productID),
            address: unescape(order.address),
            county: unescape(order.county),
            clientCode: unescape(order.clientCode),
          };
        }),
      };

    case types.UPDATE_ORDER_ASSIGNMENT_SUCCESS:
      toast.default("Order Assigned: " + action.order.id);

      const assignedOrder = {
        ...action.order,
        productID: unescape(action.order.productID),
        address: unescape(action.order.address),
        county: unescape(action.order.county),
        clientCode: unescape(action.order.clientCode),
      };
      return {
        allOrders: state.allOrders.map((order) =>
          order.id === action.order.id ? assignedOrder : order
        ),
      };

    case types.CLEAR_ORDERS:
      return [];

    default:
      return { ...state };
  }
}
