import * as types from "../../actions/PNA/actionTypes";

export default function orderAssignmentReducer(state = [], action) {
  switch (action.type) {
    case types.SET_ORDERS_FOR_ASSIGNMENT:
      return {
        selectedOrdersForAssignment: action.selectedOrdersForAssignment,
      };

    case types.REMOVE_ORDER_ASSIGNMENT:
      return {
        selectedOrdersForAssignment: state.selectedOrdersForAssignment.filter(
          (order) => order.id !== action.order.data.updateOrders.id
        ),
      };

    default:
      return state;
  }
}
