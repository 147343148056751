import authClient from "./auth";
import { Auth, API } from "aws-amplify";

export default {
  getAllStatus() {
    if (authClient.role === "orderdesk") {
      return [
        { value: "open", label: "Open" },
        { value: "cancelled", label: "Cancelled" },
        { value: "clarification", label: "Clarification" },
        { value: "complete", label: "Complete" },
      ];
    } else {
      return [
        { value: "open", label: "Open" },
        { value: "pending", label: "Pending" },
        { value: "qcreview", label: "QC Review" },
        { value: "mailaway", label: "Mailaway" },
        { value: "complete", label: "Complete" },
        { value: "closed", label: "Closed" },
        { value: "cancelled", label: "Cancelled" },
        { value: "clarification", label: "Clarification" },
      ];
    }
  },

  searchByTypesForReports() {
    return [
      { value: "all", label: "All" },
      { value: "open", label: "Open" },
      { value: "pending", label: "Pending" },
      { value: "qcreview", label: "QC Review" },
      { value: "mailaway", label: "Mailaway" },
      { value: "complete", label: "Complete" },
      { value: "closed", label: "Closed" },
      { value: "cancelled", label: "Cancelled" },
      { value: "clarification", label: "Clarification" },
    ];
  },

  searchByTypesForSearch() {
    return [
      { value: "id", label: "Order ID" },
      { value: "borrowerName", label: "Borrower Name" },
      { value: "clientCode", label: "Client Code" },
      { value: "clientProduct", label: "Client Product" },
      { value: "productID", label: "Product ID" },
      // { value: "status", label: "Status" },
      { value: "address", label: "Address" },
      { value: "state", label: "State" },
      { value: "county", label: "County" },
      { value: "userID", label: "User ID" },
      { value: "userName", label: "User Name" },
    ];
  },

  getAllClientProducts() {
    return [
      { value: "hoi", label: "HOI" },
      { value: "hoiUpdate", label: "HOI-Update" },
      { value: "mls", label: "MLS" },
      { value: "mlsUpdate", label: "MLS-Update" },
      { value: "payoff", label: "Payoff" },
      { value: "PayoffUpdate", label: "Payoff-Update" },
      { value: "taxSearch", label: "Tax Search" },
      { value: "updateTS", label: "Update TS" },
    ];
  },

  shouldCaptureComments(orderStatus) {
    return (
      orderStatus === "QC Review" ||
      orderStatus === "Mailaway" ||
      orderStatus === "Complete" ||
      orderStatus === "Clarification" ||
      orderStatus === "Closed"
    );
  },

  shouldCaptureCost(orderStatus) {
    return (
      orderStatus === "Complete" ||
      orderStatus === "Closed" ||
      orderStatus === "Mailaway"
    );
  },

  shouldCaptureCancellationReason(orderStatus) {
    return orderStatus === "Cancelled";
  },

  shouldCapturePendingReason(orderStatus) {
    return orderStatus === "Pending";
  },

  canFetchUsersFromCognito() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canAddOrders() {
    return authClient.role === "admin" || authClient.role === "orderdesk";
  },

  canAssignOrders() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canViewFilters() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canReviewPendingOrders() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canReviewCompletedOrders() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canReviewClarificationOrders() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canReviewMailawayOrders() {
    return authClient.role === "admin" || authClient.role === "teamlead";
  },

  canSeeAllOrders() {
    return (
      authClient.role === "admin" ||
      authClient.role === "teamlead" ||
      authClient.role === "orderdesk"
    );
  },

  isATypeOfProcessor() {
    return (
      authClient.role === "admin" ||
      authClient.role === "teamlead" ||
      authClient.role === "processor" ||
      authClient.role === "qcauditor"
    );
  },

  canCaptureProcessorName(orderStatus) {
    return (
      orderStatus === "Pending" ||
      orderStatus === "Mailaway" ||
      orderStatus === "QC Review" ||
      orderStatus === "Clarification" ||
      orderStatus === "Complete"
    );
  },

  canEditOrder() {
    return authClient.role === "admin" || authClient.role === "orderdesk";
  },

  canEditShipmentCost() {
    return (
      authClient.role === "admin" ||
      authClient.role === "orderdesk" ||
      authClient.role === "teamlead" ||
      authClient.role === "processor"
    );
  },

  async isQCAuditor(cognitoUsername) {
    if (!cognitoUsername) return false;

    let isQcAuditor = false;
    let nextToken;
    let apiName = "AdminQueries";
    let path = "/listGroupsForUser";
    let myInit = {
      queryStringParameters: {
        username: cognitoUsername,
        token: nextToken
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    };

    try {
      const { NextToken, ...cognitoGroups } = await API.get(
        apiName,
        path,
        myInit
      );
      nextToken = NextToken;

      for (let group of cognitoGroups.Groups) {
        if (group.GroupName === "qcauditor") {
          isQcAuditor = true;
          break;
        }
      }
    } catch (e) {
      console.log(e);
    }

    return isQcAuditor;
  }
};
