/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5761a12d-d064-4c8f-8ef9-07465ba8890e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_V83EWchUM",
    "aws_user_pools_web_client_id": "4nm56c8ilqrpo9j1l8q4786h10",
    "oauth": {},
    "aws_content_delivery_bucket": "pnataxjuly03-20201220061805-production-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d328xyjgwbg37b.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jec1wrwcwe.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "pnatax-attachments195205-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
