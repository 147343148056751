import * as types from "../../actions/PNA/actionTypes";
import toast from "../../../common/utils/toast";

export default function attachmentReducer(state = [], action) {
  switch (action.type) {
    case types.GET_ATTACHMENTS_SUCCESS:
      return { ...state, allAttachments: action.attachments };

    case types.UPLOAD_ATTACHMENT_SUCCESS:
      toast.default("Uploaded: " + action.attachment.key);
      let attachment = state.allAttachments.find(
        (attachment) => attachment.key === action.attachment.key
      );

      if (attachment) {
        return {
          ...state,
          allAttachments: [...state.allAttachments],
        };
      } else {
        // Add only when it is not in the state
        return {
          ...state,
          allAttachments: [...state.allAttachments, { ...action.attachment }],
        };
      }

    case types.DELETE_ATTACHMENT_SUCCESS:
      toast.default("Deleted: " + action.key);
      return {
        ...state,
        allAttachments: state.allAttachments.filter(
          (attachment) => attachment.key !== action.key
        ),
      };

    case types.DOWNLOAD_ATTACHMENT_SUCCESS:
      toast.default("Downloaded: " + action.key);
      return state;

    case types.UPDATE_CURRENT_UPLOAD_COUNT:
      // TBD - Complete all the -ve use cases for this.
      if (action.operation === "increment") {
        if ("currentUploadCount" in state) {
          return { ...state, currentUploadCount: state.currentUploadCount + 1 };
        } else {
          return { ...state, currentUploadCount: 1 };
        }
      } else
        return { ...state, currentUploadCount: state.currentUploadCount - 1 };

    default:
      return state;
  }
}
