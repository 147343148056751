import { toast } from "react-toastify";
import { css } from "glamor";

export default {
  default(msg, options = {}) {
    return toast(msg, {
      // Merge additionals options
      ...options,
      className: css({
        color: "#FFFFFF !important",
        minHeight: "60px !important",
        borderRadius: "8px !important",
        background: "#5C2AF4 !important",
        boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3) !important",
      }),
      progressClassName: css({
        background: "#ACA2F6 !important",
      }),
      closeButton: false,
    });
  },
  success(msg, options = {}) {
    return toast(msg, {
      // Merge additionals options
      ...options,
      closeOnClick: false,
      className: css({
        color: "#000000 !important",
        minHeight: "60px !important",
        borderRadius: "8px !important",
        background: "#18F2B0 !important",
        boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3) !important",
      }),
      progressClassName: css({
        background: "#02271C !important",
      }),
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: css({
        color: "#FFF !important",
        minHeight: "60px !important",
        borderRadius: "8px !important",
        background: "#F42a67 !important",
        boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3) !important",
      }),
      progressClassName: css({
        background: "#FFDEE8 !important",
      }),
      closeButton: false,
    });
  },
};
