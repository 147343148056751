import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "teamlead", "qcauditor", "processor", "orderdesk"],
    navLink: "/",
  },
  {
    id: "search",
    title: "Search",
    type: "item",
    icon: <Icon.Search size={20} />,
    permissions: ["admin", "teamlead", "qcauditor", "processor", "orderdesk"],
    navLink: "/search",
  },
  {
    id: "reports",
    title: "Reports",
    type: "item",
    icon: <Icon.FilePlus size={20} />,
    permissions: ["admin", "teamlead"],
    navLink: "/reports",
  },
  {
    id: "admin",
    title: "Admin",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin"],
    children: [
      {
        id: "manageUsers",
        title: "Manage Users",
        type: "item",
        icon: <Icon.UserPlus size={15} />,
        navLink: "/pages/signup",
        permissions: ["admin"],
      },
      {
        id: "ManageRoles",
        title: "Manage Roles",
        type: "item",
        icon: <Icon.Aperture size={15} />,
        navLink: "/pages/role",
        permissions: ["admin"],
      },
      {
        id: "ManageClientCode",
        title: "Client Code Mgmt",
        type: "item",
        icon: <Icon.Aperture size={15} />,
        navLink: "/pages/clientcode",
        permissions: ["admin", "teamlead"],
      },
    ],
  },
];

export default navigationConfig;
