export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CLEAR_ORDERS = "CLEAR_ORDERS";

export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const LOAD_ORDER_SUCCESS = "LOAD_ORDER_SUCCESS";
export const LOAD_ORDERS_BY_TYPE_SUCCESS = "LOAD_ORDERS_BY_TYPE_SUCCESS";

export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";

export const SET_ORDERS_FOR_ASSIGNMENT = "SET_ORDERS_FOR_ASSIGNMENT";
export const UPDATE_ORDER_ASSIGNMENT_SUCCESS =
  "UPDATE_ORDER_ASSIGNMENT_SUCCESS";
export const REMOVE_ORDER_ASSIGNMENT = "REMOVE_ORDER_ASSIGNMENT";

export const GET_ATTACHMENTS_SUCCESS = "GET_ATTACHMENTS_SUCCESS";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENTS_SUCCESS";
export const DOWNLOAD_ATTACHMENT_SUCCESS = "DOWNLOAD_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const UPDATE_CURRENT_UPLOAD_COUNT = "UPDATE_CURRENT_UPLOAD_COUNT";

export const LOAD_REPORT_ORDERS_SUCCESS = "LOAD_REPORT_ORDERS_SUCCESS";

export const UPDATE_SPINNER_STATUS = "UPDATE_SPINNER_STATUS";

export const FILTER_ORDERS = "FILTER_ORDERS";

export const LOAD_ACTIVITIES_SUCCESS = "LOAD_ACTIVITIES_SUCCESS";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";

export const LOAD_CLIENTCODE_SUCCESS = "LOAD_CLIENTCODE_SUCCESS";
export const CREATE_CLIENTCODE_SUCCESS = "CREATE_CLIENTCODE_SUCCESS";
export const SEARCH_CLIENTCODE_SUCCESS = "SEARCH_CLIENTCODE_SUCCESS";

